import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect,   } from 'react';

import { Layout, theme, ConfigProvider, Button, Dropdown, Space, Col, Row, Card, Timeline, Avatar  } from 'antd';
import sgwabLogo from './images/sgwab.png';
import { SunOutlined, MoonFilled, DatabaseOutlined,ReadOutlined } from '@ant-design/icons';
const { Meta,   } = Card;



const { Header, Content, Footer } = Layout;
const { defaultAlgorithm, darkAlgorithm } = theme;
const items = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://anduin.aiursoft.cn/page/scale">
        Display PPI Calculator
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        Translate
      </a>
    ),
  },
  {
    key: '3',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        Repo Manhours
      </a>
    ),
  },
  {
    key: '4',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        Code Runner
      </a>
    ),
  },
  {
    key: '5',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        Remote Desktop
      </a>
    ),
  },
  {
    key: '6',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        File Transfer
      </a>
    ),
  },
  {
    key: '7',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://yiyan.baidu.com/">
        文心一言
      </a>
    ),
  },
  {
    key: '8',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://openai.com/">
        openai
      </a>
    ),
  },
  {
    key: '9',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://blog.ruasoft.cn/">
        Blog
      </a>
    ),
  },
];
const items2 = [
 { 
    
       
        key: '3-1',
        label: (
          <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
            Nuget Registry
          </a>
        ),
        icon: <DatabaseOutlined />,
      },
      {
        key: '3-2',
        label: (
          <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
            Docker Registry
          </a>
        ),
        icon: <DatabaseOutlined />,
      },
      {
        key: '3-3',
        label: (
          <a target="_blank"  rel="noopener noreferrer" href="https://www.aliyun.com">
            npm Registry
          </a>
        ),
        icon: <DatabaseOutlined />,
      },
      {
        key: '3-4',
        label: (
          <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
            APT Registry
          </a>
        ),
        icon: <DatabaseOutlined />,
   
    
  },
];

const menu1Props = {
  items,
};
const menu2Props = {
  items: items2,
};

const App = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();  const [isDarkMode, setIsDarkMode] = useState(false);
  // const [height, setHeight] = useState('10vh');
  const [shouldRenderBr, setShouldRenderBr] = useState(window.innerWidth < 576);

 

  const handleClick = () => {
    setIsDarkMode((previousValue) => !previousValue);
   
  };

  const cardContainerStyle = {
    minWidth: 240,
    maxWidth: '96%',
  }; 

  useEffect(() => {
    const handleResize = () => {
      setShouldRenderBr(window.innerWidth < 576);
    }; 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <ConfigProvider theme={{ algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm }}>
      <Layout style={{ height: '100dh' ? '100dh' : '100dvh' }}>
        <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: isDarkMode ? '#001529' : '#21468C', position: 'fixed', zIndex: '20', width: '100%' }} className="right-align">
          <div  >
            <img src={logo} className="App-logo" alt="logo" style={{  }} />
            <span style={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.85)' : 'white', fontSize: '24px', marginLeft: '4px' }}>Ruasoft</span>
          </div>
          <Space direction="vertical"  >
            <Space wrap>
              <Button style={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.85)' : 'white', marginTop: '17px', marginRight: '-10px' }} color="default" variant="text" onClick={handleClick}  >{isDarkMode ? <SunOutlined /> : <MoonFilled />}
              </Button>
              <Dropdown menu={menu1Props} arrow placement="bottom">
                <Button style={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.85)' : 'white' }} color="default" variant="text">  Toolbox</Button>
              </Dropdown>
              <Dropdown menu={menu2Props} arrow placement="bottom"  >
                <Button  style={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.85)' : 'white' }} color="default" variant="text">Registry</Button>
              </Dropdown>

            </Space>
          </Space>
        </Header>
        <Content style={{
          padding: '10vh 5vh 13vh 5vh', display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }} className='content'  >
          <Row>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
              <br />
              <Card style={cardContainerStyle} hoverable={false}>
                <Meta
                  avatar={<Avatar icon={<ReadOutlined />} />}
                  title="Books"
                />
                <p><a href='https://base64.us/'>Base64 在线编码解码</a></p>
                <p><a href='https://www.uuid.online/'>UUID</a></p>
                <p><a href='https://yiyan.baidu.com/'>YiYan</a></p>
                
           
              </Card>

            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8} >
              <br />
              <Card style={cardContainerStyle}>
                <Meta
                  avatar={<Avatar icon={<ReadOutlined />} />}
                  title="Books"
                />
                <p><a href='https://memos.ruasoft.cn/'>Memos</a></p>
                <p><a href='https://gist.ruasoft.cn/'>Gist</a></p>
                <p><a href='https://git.ruasoft.cn/'>Gite</a></p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
              <br />
                <Card style={cardContainerStyle}>
                    <Meta
                        avatar={<Avatar icon={<ReadOutlined/>}/>}
                        title="Books"
                    />
                    <p><a href='https://rdf.aiursoft.cn/'>Blog</a></p>
                    <p><a href='https://gitlab.ruasoft.cn/'>GitLab</a></p>
                    <p><a href='https://rdf.ruasoft.cn/'>EarlyRdf</a></p>
                </Card>
            </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8} >
              <br />
              <Card style={cardContainerStyle}>
                <Meta
                  avatar={<Avatar icon={<ReadOutlined  />}  />}
                  title="Books"
                />
                <a href='https://www.aiursoft.cn/'><p>Friendly Aiur</p></a>
                <a href='https://cook.aiursoft.cn/'><p>How To Cook</p></a>
                <a href='https://oi.aiursoft.cn/'><p>OI Wiki</p></a>
                <a href='https://ctf.aiursoft.cn/'><p>CTF Wiki</p></a>
              </Card>
            </Col>
          
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
              <br />
              <Card style={{
                minWidth: 240,
                maxWidth: '98%'
                }}>
                <Timeline 
                  style={{ minWidth: '500'  }}
                  reverse={false}
                  mode="alternate"
            
                  items={[
                   
                    {
                      color: 'green',
                      children: '学习 《工业和信息化部关于开展移动互联网应用程序备案工作的通知》解读',    
                      label: '2024-11-09'                
                    },
                    {
                      children: '学习 工业和信息化部关于开展移动互联网应用程序备案工作的通知',
                      label:'2024-11-09'
                    },
                    {
                      children: '学习 江苏省算力基础设施发展专项规划 2024-11-08',
                    },
                    {
                      children: 'ICP备案申请已通过审核',
                      label:'审核通过日期：2024-11-08'
                    },
                     {
                      children: '学习 华为云 《网站备案用户指南（公安备案和经营性备案）》 2024-11-01',
                    },
                    {
                      children: '网站负责人诵读《网站备案信息真实性承诺书》 2024-11-01',
                    },
                    {
                      children: '签署 ICP备案信息真实性承诺书 2024-11-01',
                    },
                    {
                      children: '签署 ICP备案信息真实性责任告知书 2024-11-01',
                    },
                    {
                      children: '学习 工信部 《互联网域名管理办法》 2024-11-01',
                    },
                    {
                      children: '学习 工信部 《工业和信息化部关于开展互联网信息服务备案用户真实身份信息电子化核验试点工作的通知》 2024-11-01',
                      label: '工信部信管函〔2019〕87号',
                    },
                    {
                      children: '学习 工信部 《互联网域名管理办法》 2024-11-01',
                    },
                    {
                      children: '学习 工信部 《工业和信息化部关于规范互联网信息服务使用域名的通知》 2024-11-01',
                    },
                    {
                      children: '学习 工信部 《非经营性互联网信息服务备案管理办法》 2024-11-01',
                    },
                    {
                      children: '学习 工信部 《互联网信息服务管理办法》 2024-11-01',
                    },
                    {
                      children: '签订华为云订单协议 2024-11-01',
                    },
                    {
                      label: <a href='https://webwhois.cnnic.cn/'>CNNIC 中国互联网络信息中心 </a>,
                      children: '中国国家顶级域名证书获取 2024-10-31'
                    },
                  ]}
                />
              </Card>
            </Col>
          </Row>

        </Content>
        <Footer className='footer' >
          Ruasoft-Homepage ©{new Date().getFullYear()}
          <br /> 网站标识码：xxxxx
          网站备案/许可证编号：
          {shouldRenderBr && <br />}
          <a href="https://beian.miit.gov.cn/" >苏ICP备2024141331号</a>

          <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32040402000882">
            <img style={{ marginBottom: '-2px' }} src={sgwabLogo} alt="全国互联网安全管理服务平台图标" />苏公网安备32040402000882号</a>
        </Footer>
      </Layout>
    </ConfigProvider>

  );
};

export default App;